import contractObject from '@/decentralizedApi/inits.js'
import {cheese} from '@/config/abi'

const getContract = (abi, address) => {
  let signerOrProvider = contractObject.getInitGlobalWeb3();
  return new signerOrProvider.eth.Contract(abi, address);
};

export let getUSDTContract = () => {
  return getContract(cheese.contracts.USDT.abi, cheese.contracts.USDT.addr);
};

export let getTokenContract = (address = cheese.contracts.Token.addr) => {
  return getContract(cheese.contracts.Token.abi, address);
};

export let getNFTContract = () => {
  return getContract(cheese.contracts.NFT.abi, cheese.contracts.NFT.addr);
};

export let getPoolContract = () => {
  return getContract(cheese.contracts.Pool.abi, cheese.contracts.Pool.addr);
};


export let getUCASHPoolContract = () => {
  return getContract(cheese.contracts.UCASHPool.abi, cheese.contracts.UCASHPool.addr);
};
