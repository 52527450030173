import axios from 'axios'


let BASE_URL = '' // 第二节配置的url 可以读取 一定要VUE_APP_A为前缀

// 环境的切换
if (process.env.NODE_ENV === 'development') {// 开发环
  BASE_URL = '';  //'/api';
} else if (process.env.NODE_ENV === 'production') {// 正式环境
  BASE_URL = '';
} else { // 测试环境
  BASE_URL = '';
}
// 创建axios实例
const http = axios.create({
  baseURL: BASE_URL,
  timeout: 5 * 1000, // 请求超时时间
})

// function startLoading() {
//   // 开始加载
//   // Loading.service({});
// }

// function endLoading() {
//   // 结束加载
//   // Vue.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
//   //   loadingInstance.close();
//   // });
// }

// 添加request拦截器
http.interceptors.request.use(
  (config) => {
    let userId = localStorage.getItem("userId")
    if (userId) {
      config.headers.userId = userId
    }
    config.headers['Accept-Language'] = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh'
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 添加response拦截器
http.interceptors.response.use((response) => {
    if (response.status !== 200) {
      return Promise.reject(response)
    }
    return Promise.resolve(response.data)
  }, (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          localStorage.removeItem("userId")
          console.log(401)
          break
        case 403:
          console.log(403)
          break
        case 404:
          console.log(404)
          break
        case 500:
          console.log(500)
          break
      }
    }
    return Promise.reject(error.response)
  },
)

function get(url, params = {}) {
  return http({
    url,
    method: 'GET',
    params,
  })
}

//封装post请求
function post(url, params = {}) {
  return http({
    url,
    method: 'POST',
    params,
  })
}

// delete请求
function del(url) {
  return http({
    url,
    method: 'DELETE',
  })
}

// get请求
function put(url, params = {}) {
  return http({
    url,
    method: 'PUT',
    params,
  })
}

export default {
  get,
  put,
  post,
  del,
}