<template>
  <div class="ulab">
    <div class="ulab-content">
      <div class="ulab-extract-ucash">
        <span class="ulab-extract-title">待提取-UCASH</span>
        <div class="ulab-extract-amount">
          <span>{{ toFixed(ucashBalance, 4) }}</span>
        </div>
        <div class="ulab-extract-button" @click="handelWithdraw(0)">提取</div>
      </div>
      <div class="ulab-extract-ucash">
        <span class="ulab-extract-title">待提取-USDT</span>
        <div class="ulab-extract-amount">
          <span>{{ toFixed(usdtBalance, 4) }}</span>
        </div>
        <div class="ulab-extract-button" @click="handelWithdraw(1)">提取(Fee: 5%)</div>
      </div>
      <div class="ulab-extract-ucash pledgeHeight">
        <span class="ulab-extract-title">质押</span>
        <div class="ulab-extract-amount">
          <span>{{ toFixed(pledgeBalance, 4) }}</span>
        </div>
        <div class="ulab-extract-day">
          <span>质押周期 (天)</span>
          <div class="day-text-list">
            <div class="day-text-list-item" v-for="(item,index) in 3" :class="{itemTextActive:selectIndex===index}" @click="handelClickDay(index)">
              {{ days[index] }}
            </div>
          </div>
        </div>
        <div class="ulab-extract-button marginTop40" @click="handelWithdraw(2)">{{ isUcashApprove ? '确认质押' : '授权' }}</div>
      </div>
      <div class="ulab-extract-line"></div>
      <van-tabs v-model="active" @click="changeTab">
        <van-tab v-for="(item,index) in tabList" :title="item" :key="index">
          <div class="list-title">
            <span>{{ active === 0 ? '质押数-UCASH' : '收益-USDT' }}</span>
            <span>{{ active === 0 ? '状态' : '收益比例-%' }}</span>
            <span>{{ active === 0 ? '质押日期' : '收益日期' }}</span>
          </div>
          <van-list v-model="loading" :finished="finished" @load="onLoad()">
            <div v-for="(item,index) in list">
              <div class="list-item" v-if="active===0">
                <span>{{ toFixed(item.Value, 2) }}</span>
                <span>{{ item.Statu === 0 ? '质押中' : '已到期' }}</span>
                <span>{{ timestampToTime(item.Time, 'yyyy-MM-dd hh:mm:ss') }}</span>
              </div>
              <div class="list-item" v-else>
                <span>{{ toFixed(item.Value, 2) }}</span>
                <span>{{ parseFloat(item.Rate) * 100 + "%" }}</span>
                <span>{{ timestampToTime(item.Time, 'yyyy-MM-dd hh:mm:ss') }}</span>
              </div>
            </div>
          </van-list>
        </van-tab>
      </van-tabs>

    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {getDepositRecord, getProfitRecord, getUCASHBalance, signatureULAB, withdrawUCASH, withdrawUSDT} from '@/api/cheese'
import {formatAmount, parseAmount, toFixed} from '@/utils/format'
import {cheese} from '@/config/abi'
import {getTokenContract, getUCASHPoolContract} from '@/utils/contractHelp'
import {MaxUint256} from '@ethersproject/constants'
import {timestampToTime} from '@/utils/utils'
import {vantLocales} from '@/i18n/i18n'

export default {
  name: "ulab",
  data() {
    return {
      active: 0,
      selectIndex: 0,
      ucashBalance: 0,
      usdtBalance: 0,
      pledgeBalance: 0,
      allowanceNum: 0,
      days: [365, 730, 1095],
      loading: false,
      finished: false,
      pageIndex: 1,
      pageMax: 10,
      list: [],
      tabList: ["质押记录", "收益记录"],
    }
  },
  computed: {
    ...mapGetters(['isConnectWallet', 'walletAddress', 'isBindSuperAddress']),
    isUcashApprove() {
      return parseFloat(this.allowanceNum) > 0 && parseFloat(this.allowanceNum) >= parseFloat(this.pledgeBalance);
    },
  },
  watch: {
    walletAddress(val, oval) {
      this.onRefresh();
      this.getIndexInfo()
      this.handleTimerPrice();
    }
  },
  created() {
    window.setLanguage = this.setLanguage
    // this.onRefresh();
    // this.getIndexInfo();
    // this.handleTimerPrice();
  },
  methods: {
    setLanguage(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("lang", lang);
      vantLocales(this.$i18n.locale);
      console.log('设置的语言是=', lang);
    },
    toFixed, timestampToTime,
    omitCharacters(address, reserve) {
      const newAddress = address.substring(0, 5) + '...' + address.substring(address.length - reserve);
      return newAddress;
    },
    handleWalletConnect() {
      if (this.isConnectWallet)
        return
      this.$emit('listenWalletConnect')
    },
    handelClickDay(index) {
      if (index > 0) {
        this.$message({ message: '敬请期待' })
        return
      }
      this.selectIndex = index
    },
    changeTab(index, title) {
      this.active = index
      this.onRefresh()
    },
    async onRefresh() {
      if (!this.walletAddress)
        return
      this.pageIndex = 1;
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      console.log("1111")
      await this.onLoad();
    },
    async onLoad() {
      console.log("2222")
      if (!this.walletAddress) {
        this.loading = false
        this.finished = true;
        return;
      }
      if (this.pageIndex === 1) {
        this.list = [];
      }
      let result = (this.active === 0 ? await getDepositRecord(this.walletAddress, this.pageIndex, this.pageMax) :
        await getProfitRecord(this.walletAddress, this.pageIndex, this.pageMax))
      if (result.status) {
        let mList = result.data
        this.list = this.list.concat(mList)
        this.loading = false;
        if (mList.length < this.pageMax) {
          this.finished = true
        } else {
          this.pageIndex++
        }
      } else {
        this.loading = false;
        this.finished = true;
      }
    },
    handleTimerPrice() {
      window.clearTimeout(this.timerPrice);
      this.timerPrice = setInterval(async () => {
        await this.getIndexInfo();
      }, 5 * 1000);
    },
    async getIndexInfo() {
      if (!this.walletAddress)
        return;
      let result = await getUCASHBalance(this.walletAddress)
      if (result.status) {
        this.ucashBalance = result.data.ucashBalance
        this.usdtBalance = result.data.usdtBalance
      } else {
        this.ucashBalance = 0
        this.usdtBalance = 0
      }
      const to = cheese.contracts.UCASHPool.addr;
      const erc20Contract = getTokenContract(cheese.contracts.UCASH.addr);

      let balance = await erc20Contract.methods.balanceOf(this.walletAddress).call();
      this.pledgeBalance = formatAmount(balance)

      const allowance = await erc20Contract.methods.allowance(this.walletAddress, to).call();
      this.allowanceNum = formatAmount(allowance)
    },
    async handelWithdraw(index) {
      if (index === 2) {
        if (this.isUcashApprove) {
          if (parseFloat(this.pledgeBalance) <= 0) {
            this.$message({ message: this.$t('message.alertMsg.alertMsg4') })
          } else {
            this.$loading.showLoading();
            this.withdraw(getUCASHPoolContract().methods.depositUcash(parseAmount(this.pledgeBalance), this.days[this.selectIndex]))
          }
        } else {
          this.$loading.showLoading();
          this.handleApprove()
        }
      } else {
        if (parseFloat(this.ucashBalance) <= 0 && index === 0) {
          this.$message({ message: this.$t('message.alertMsg.alertMsg4') })
          return
        }

        if (parseFloat(this.usdtBalance) <= 0 && index === 1) {
          this.$message({ message: this.$t('message.alertMsg.alertMsg4') })
          return
        }


        if (parseFloat(this.usdtBalance) < 100 && index === 1) {
          this.$message({ message: this.$t('message.alertMsg.alertMsg24') })
          return
        }

        this.$loading.showLoading();
        signatureULAB(this.walletAddress, async (signature, error) => {
          console.log('signature', signature)
          if (error) {
            this.$loading.hiddenLoading();
            this.$message({ message: this.$t('message.alertMsg.alertMsg13') });
          } else {
            switch (index) {
              case 0: {
                let result = await withdrawUCASH(signature, this.walletAddress)
                if (result.status) {
                  // let id = result.data.id;
                  // let to = result.data.to;
                  // let value = result.data.value;
                  // let vs = result.data.vs;
                  // let rs = result.data.rs;
                  // let ss = result.data.ss;
                  // this.withdraw(getUCASHPoolContract().methods.withdrawUcash(id, to, value, vs, rs, ss))
                  this.$loading.hiddenLoading();
                  this.$message({ message: result.message });
                  this.getIndexInfo()
                } else {
                  this.$loading.hiddenLoading();
                  this.$message({ message: result.message });
                }
                break
              }
              case 1: {
                let result = await withdrawUSDT(signature, this.walletAddress)
                if (result.status) {
                  // let id = result.data.id;
                  // let to = result.data.to;
                  // let value = result.data.value;
                  // let vs = result.data.vs;
                  // let rs = result.data.rs;
                  // let ss = result.data.ss;
                  // this.withdraw(getUCASHPoolContract().methods.withdrawUsdt(id, to, value, vs, rs, ss))
                  this.$loading.hiddenLoading();
                  this.$message({ message: result.message });
                  this.getIndexInfo()
                } else {
                  this.$loading.hiddenLoading();
                  this.$message({ message: result.message });
                }
                break
              }
            }
          }
        })
      }
    },
    handleApprove() {
      let erc20Contract = getTokenContract(cheese.contracts.UCASH.addr)
      const amount = MaxUint256;
      const to = cheese.contracts.UCASHPool.addr;
      erc20Contract.methods.approve(to, amount)
        .send({ from: this.walletAddress })
        .on('transactionHash', hash => {
          console.log('hash', hash);
          // newTransactionHash(hash)
        })
        .on('receipt', receipt => {
          this.$loading.hiddenLoading();
          this.allowanceNum = amount;
          this.$message({ message: this.$t('message.alertMsg.alertMsg22') });
        })
        .on('error', (error, receipt) => {
          this.$loading.hiddenLoading();
          if (error.code === 4001) {
            this.$message({ message: this.$t('message.alertMsg.alertMsg13') });
          } else {
            if (error.toString().includes("newBlockHeaders")) {
              console.error("The web3.js bug hit https://github.com/ethereum/web3.js/issues/3498");
              console.error(error);
              this.getIndexInfo();
            } else
              this.$message({ message: error.message });
          }
          throw error;
        });
    },
    withdraw(poolContract) {
      try {
        poolContract
          .send({ from: this.walletAddress })
          .on('transactionHash', hash => {
            console.log('hash', hash);
            // newTransactionHash(hash)
          })
          .on('receipt', receipt => {
            this.$loading.hiddenLoading();
            this.$message({ message: this.$t('message.alertMsg.alertMsg8') });
          })
          .on('error', (error, receipt) => {
            this.$loading.hiddenLoading();
            console.log(error)
            if (error.code === 4001) {
              this.$message({ message: this.$t('message.alertMsg.alertMsg13') });
            } else {
              if (error.toString().includes("newBlockHeaders")) {
                console.error("The web3.js bug hit https://github.com/ethereum/web3.js/issues/3498");
                console.error(error);
                this.getIndexInfo();
              } else
                this.$message({ message: error.message });
            }
            throw error;
          });
      } catch (e) {
        console.log(e)
        this.$loading.hiddenLoading();
        this.$message({ message: e.message });
      }
      this.getIndexInfo()
    },
  }
}


</script>


<style scoped lang="scss">
.ulab {
  width: 100%;
  height: 100%;
  background-color: white;
  overflow: auto;
  .ulab-content {
    padding-top: 0.1px;
    .ulab-extract-ucash {
      display: flex;
      flex-direction: column;
      width: 690px;
      min-height: 370px;
      border-radius: 10px;
      background-color: #f7f7f7;
      margin: 40px auto 0;
      .ulab-extract-title {
        margin: 40px auto 0;
        font-family: PingFangSC;
        font-size: 26px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #18273c;
      }
      .ulab-extract-amount {
        margin: 20px auto 0;
        width: 650px;
        height: 110px;
        border-radius: 10px;
        border: solid 1px #e9ebef;
        background-color: #fff;
        span {
          font-family: DIN;
          font-size: 56px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 110px;
          letter-spacing: normal;
          text-align: center;
          color: #18273c;
        }
      }
      .ulab-extract-button {
        margin: 30px auto 0;
        width: 650px;
        height: 90px;
        border-radius: 10px;
        background-color: #0d4ca7;
        font-family: PingFangSC;
        font-size: 32px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 90px;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
      }
      .marginTop40 {
        margin-top: 40px;
      }
      .ulab-extract-day {
        margin: 40px auto 0;
        width: 650px;
        height: 60px;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        span {
          font-family: PingFangSC;
          font-size: 28px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #627795;
        }
        .day-text-list {
          display: flex;
          flex-direction: row;

          .day-text-list-item {
            margin-left: 15px;
            width: 120px;
            height: 60px;
            border-radius: 6px;
            border: solid 1px #627795;
            font-family: PingFangSC;
            font-size: 28px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 60px;
            letter-spacing: normal;
            text-align: center;
            color: #627795;
          }
          .itemTextActive {
            font-weight: bold;
            border: solid 1px #0d4ca7;
            color: #0d4ca7;
          }
        }
      }
    }
    .pledgeHeight {
      height: 480px;
    }
    .ulab-extract-line {
      margin-top: 50px;
      width: 100%;
      height: 20px;
      background-color: #f7f7f7;
    }
    .van-list {
      min-height: 50vh;
    }
    .list-title {
      margin: 30px 30px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      span {
        font-family: PingFangSC-Regular;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #627795;
        &:first-child {
          width: 180px;
          text-align: left;
        }
        &:nth-child(2) {
          width: 140px;
          text-align: center;
        }
        &:last-child {
          width: 280px;
          text-align: right;
        }
      }
    }
    .list-item {
      margin: 30px 30px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      span {
        font-family: PingFangSC-Regular;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #233858;
        &:first-child {
          width: 180px;
          text-align: left;
        }
        &:nth-child(2) {
          width: 140px;
          text-align: center;
        }
        &:last-child {
          width: 280px;
          text-align: right;
        }
      }
    }
  }
}
</style>