import request from "@/api/request";
import contractObject from '@/decentralizedApi/inits'

const BASE_URL = process.env.NODE_ENV === 'development' ? 'ulab/' : process.env.VUE_APP_API_URL

export function getUCASHBalance(address) {
  let params = { address: address };
  let url = BASE_URL + 'ucash/balance';
  return request.get(url, params);
}

export function withdrawUCASH(sign, address) {
  let params = { sign: sign, address: address };
  let url = BASE_URL + 'ucash/withdrawucash';
  return request.post(url, params);
}

export function withdrawUSDT(sign, address) {
  let params = { sign: sign, address: address };
  let url = BASE_URL + 'ucash/withdrawusdt';
  return request.post(url, params);
}

export function getProfitRecord(address, page, offset) {
  let params = { page: page, offset: offset, address: address };
  let url = BASE_URL + 'ucash/depositprofitrec';
  return request.get(url, params);
}

export function getDepositRecord(address, page, offset) {
  let params = { page: page, offset: offset, address: address };
  let url = BASE_URL + 'ucash/depositrec';
  return request.get(url, params);
}


/**
 * 签名
 * @param walletAddress
 * @param userId
 * @param callBack
 */
export function signature(walletAddress, userId, callBack) {
  let web3 = contractObject.getInitGlobalWeb3()
  let data = web3.utils.keccak256(web3.utils.fromUtf8(userId))
  web3.eth.personal.sign(
    data,
    walletAddress,
    "",
    async (error, signature) => {
      callBack(signature, error)
    }
  );
}

export function signatureULAB(walletAddress, callBack) {
  let web3 = contractObject.getInitGlobalWeb3()
  let address = web3.utils.toChecksumAddress(walletAddress)
  let data = web3.utils.keccak256(web3.utils.fromUtf8(address))
  web3.eth.personal.sign(
    data,
    walletAddress,
    "",
    async (error, signature) => {
      console.log("error", error)
      callBack(signature, error)
    }
  );
}